import {Injectable} from '@angular/core';
import {
  HttpContextToken,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import {AuthService} from '../services/auth/auth.service';
import {BehaviorSubject, catchError, filter, finalize, Observable, take, throwError} from 'rxjs';
import {switchMap} from "rxjs/operators";
import {Router} from "@angular/router";
import {LoadingService} from "../services/utils/loading.service";
import {log} from "@angular-devkit/build-angular/src/builders/ssr-dev-server";

export const SkipLoading = new HttpContextToken<boolean>(() => false);


/**
 * Implements `HttpInterceptor` to append Authorization header to the request
 *
 * {@example Authorization: Bearer access_token}
 */
@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private authService: AuthService,
    private router: Router,
    private loadingService: LoadingService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.context.get(SkipLoading)) {
      // Load spinner
      this.loadingService.show();
    }

    // Skip adding token to the token endpoint
    if (req.url.includes('/auth/token/')) {
      return next.handle(req).pipe(
        finalize(() => {
          this.loadingService.hide();
        })
      );
    }

    if (this.authService.accessToken) {
      req = this.addTokenHeader(req);
    }

    return next.handle(req).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          console.log('401 has occurred for url: ', req.url);
          return this.handle401Error(req, next);
        } else {
          return throwError(error);
        }
      }),
      finalize(() => {
        this.loadingService.hide();
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      console.log('refreshing token...')
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshToken().pipe(
        switchMap((token: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token.access);
          return next.handle(this.addTokenHeader(request));
        }),
        catchError((err) => {
          this.isRefreshing = false;
          this.authService.logout().finally(() => {
            this.router.navigate(['/auth/login']);
          });
          return throwError(err);
        })
      );
    } else {
      console.log('already refreshing...')
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(() => next.handle(this.addTokenHeader(request)))
      );
    }
  }

  addTokenHeader(request: HttpRequest<any>) {
    console.log('adding token to header of: ', request.url);
    return request.clone({
      setHeaders: {
        // Authorization: `${this.auth.tokenType} ${this.auth.accessToken}`,
        ...(this.authService.tokenType && this.authService.accessToken ?
          {Authorization: `${this.authService.tokenType} ${this.authService.accessToken}`} : {}),
        'mfa-session-id': `${this.authService.mfaSessionId}`
      }
    });
  }
}
