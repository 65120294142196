export const environment = {
  production: true,
  env: 'production',
  defaultServeDomain: 'tpbp.in',
  apiUrl: 'https://api.tapbeep.com/',
  qrAPIKey: 'ocknsniphzpetujxeuepuwpmczxhnfneqmijaugz',
  clientKey: '3WnWoJgPMY3b0YQfkYCM1ZVCH13YRDDOd1GeeIDM',
  clientSecret: 'dkFfrZTfU3aaK31B8kD13YuMGPAWGD7kWcuHlRnZ8JKzZFnWMlk52sNLQon6KB7VNP3Q0EtXqsCzVyQmJBXxUjMOyeLWjGrJBHXr5Y5vtjeqWdSfs3foQERtJvaHwRRK',
  stripeClientKey: 'pk_test_51JBeMeSJ2CHFsg9ozW0IzHXs7sIpqeCeTQFQMlWxgMPC0n6w3czVUGS2jjKnDIh71KqKtFBkcbZZKDXpM2M4wsW9004h9Vpheo',
  demoBookUrl: 'https://calendly.com/tapbeep/30min',
  planPricing: {
    lite: {
      monthly: '5cc7c2fb-0a29-4d23-bb9d-6377f29b3a97',
      yearly: 'e80359c3-89c0-4eef-ad31-d203f82da531'
    },
    standard: {
      monthly: '0b7b738c-fdb2-4105-883a-26fc5866bce2',
      yearly: '040ad2f2-fc6a-43ea-b25c-62a123c6efd1',
    },
    pro: {
      monthly: 'b97c67ce-d310-4cd9-b313-8d377f9258e6',
      yearly: 'e1b21d95-7847-4d3b-8946-64fc9d07dd28'
    },
    enterprise: {
      monthly: null,
      yearly: null
    },
    free: {
      monthly: '1',
      yearly: '1'
    }
  }
};
