import {Component, Inject, OnInit} from '@angular/core';
import {NavigationEnd, NavigationStart, Router} from "@angular/router";
import {DOCUMENT} from "@angular/common";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private router: Router,
    @Inject(DOCUMENT)private document: Document
  ) {
  }

  ngOnInit() {
    this.routerEvents();
  }

  /**
   * Listen to router events to handle redirection to login/signup with return URL.
   */
  routerEvents() {
    const authPaths = ['/', '/auth/login', '/auth/register', '/auth/success', '/auth/logout'];
    const exceptionPaths = ['/auth/logout'];

    this.router.events.subscribe(event => {

      // console.log('4a url change...: ', event);
      // console.log('4a route url: ', this.router.url);

      if (event instanceof NavigationEnd) {
        this.updateCanonicalURL();
      }


      // Check if the event is a NavigationStart event and if the current URL is an authentication path
      if (event instanceof NavigationStart && authPaths.includes(event.url) && event.url !== '/') {

        // console.log('1 event url: ', event.url)
        // console.log('2 current path: ', this.router.url);
        // console.log('-------------')

        // Return if current path is an exception path, so that we don't have to add a redirection url.
        if (exceptionPaths.includes(event.url)) {
          return;
        }

        // Split the current URL to separate path and query parameters
        const currentPath = this.router.url.split('?');
        let continueUrl = '';

        if (authPaths.includes(currentPath[0])) {
          if (currentPath[1]) {
            this.router.navigateByUrl(`${event.url}?${currentPath[1]}`);
          }
          return; // Return as current path is also an authentication path and does not require 'continue=' to be added.
        }

        continueUrl = encodeURIComponent(this.router.routerState.snapshot.url);
        this.router.navigateByUrl(`${event.url}?continue=${continueUrl}`);
      }
    });
  }

  updateCanonicalURL(): void {
    // Exclude query params from the URL
    const urlWithoutQuery = this.router.url.split('?')[0];
    const canonicalUrl = `${window.location.origin}${urlWithoutQuery === '/' ? '' : urlWithoutQuery}`;

    let link: HTMLLinkElement | null = this.document.querySelector("link[rel='canonical']");
    let ogLink: HTMLMetaElement | null = this.document.querySelector("meta[property='og:url']");
    let twitterLink: HTMLMetaElement | null = this.document.querySelector("meta[property='twitter:url']");

    if (link) {
      // If the canonical link already exists, update it
      link.setAttribute('href', canonicalUrl);
      console.log('Canonical created: ', canonicalUrl);
    } else {
      // Otherwise, create a new canonical link element
      link = this.document.createElement('link');
      link.setAttribute('rel', 'canonical');
      link.setAttribute('href', canonicalUrl);
      this.document.head.appendChild(link);
      console.log('Canonical updated: ', canonicalUrl);
    }

    // og
    if (ogLink) {
      ogLink.setAttribute('content', canonicalUrl);
    } else {
      ogLink = this.document.createElement('meta');
      ogLink.setAttribute('content', canonicalUrl);
      ogLink.setAttribute('property', 'og:url');
      this.document.head.appendChild(ogLink);
    }

    // twitter
    if (twitterLink) {
      twitterLink.setAttribute('content', canonicalUrl);
    } else {
      twitterLink = this.document.createElement('meta');
      twitterLink.setAttribute('content', canonicalUrl);
      twitterLink.setAttribute('property', 'twitter:url');
      this.document.head.appendChild(twitterLink);
    }
  }
}
