import {Observable, Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SubscriptionResponse} from "../../models/subscription/subscription.model";
import {PlansModel, SubscriptionDowngradeData} from "../../models/subscription/plans.model";
import {UserProfileModel} from "../../models/auth/profile.model";
import {SubscriptionPlans} from "../../data/subscription/plans.data";
import {AppHttpClient} from "../../../app-http-client";

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  /**
   * Store users current plan
   */
  public userPlan: SubscriptionResponse;
  /**
   * Emit plan change value
   */
  public planChange: Subject<SubscriptionResponse> = new Subject<SubscriptionResponse>();
  /**
   * Emit custom plan value
   */
  public isCustomPlan = false;
  /**
   * Stores all main plan names
   */
  mainPlanNames: Array<string> = [];
  // Store theme on service initialize
  private readonly subscriptionDowngradeData: Array<SubscriptionDowngradeData>;
  /**
   * Endpoint for plans and subscriptions
   */
  private planEndpoint = 'plans/';
  // private paymentEndpoint = 'payment/';
  /**
   * Endpoint for Enterprise and Request Quote Form Data
   */
  private requestPlanEndpoint = 'utils/';
  /**
   * Endpoint to submit the feedback form
   */
  private feedBackEndpoint = 'utils/feedback/'
  /**
   * Store available subscription plans
   */
  private readonly subscriptionPlans: Array<PlansModel>;

  constructor(
    private appHttp: AppHttpClient,
    private http: HttpClient
  ) {
    this.subscriptionPlans = SubscriptionPlans;

    // Store all plan names
    this.mainPlanNames = this.subscriptionPlans.map(i => i.name.toLowerCase());

    // Listen to plan change
    this.planChange.subscribe({
      next: (res) => {
        this.userPlan = res;
        this.checkCustomPlan(res?.plan?.name);
      }
    });

    this.checkCustomPlan();

    // this.subscriptionDowngradeData = downgradePlanData;
  }

  /**
   *  Below method is used to get  all the plans and its corresponding data
   */
  public plans() {
    return this.subscriptionPlans;
  }

  /**
   * Get Current Plan of User
   */
  public currentPlan() {
    const url = `${this.planEndpoint}current/`;
    this.appHttp.Get(url).subscribe({
      next: (res: any) => {
        this.planChange.next(res as SubscriptionResponse);
      }
    });
  }

  /**
   * Get Current Plan of User
   */
  public getCurrentPlan(): Observable<SubscriptionResponse> {
    const url = `${this.planEndpoint}current/`;
    return this.appHttp.Get(url);
  }

  /**
   * Set user plan manually
   * @param data Subscription response data
   */
  public setUserPlan(data: SubscriptionResponse) {
    this.planChange.next(data);
  }

  /**
   * Returns true if custom plan is active
   * main plans includes - Lite, standard, pro, ultra lite
   */
  checkCustomPlan(plan?: string) {
    const userPlan = plan || this.getUserPlan();
    if (userPlan) {
      if (!this.mainPlanNames.includes(userPlan.toLowerCase())) {
        this.isCustomPlan = true;
        return true;
      }
    }
    this.isCustomPlan = false;
    return false;
  }

  /**
   * Get user plan
   */
  getUserPlan(): string {
    try {
      const profile = JSON.parse(<string>localStorage.getItem('userProfile')) as UserProfileModel;
      if (profile) {
        return profile.subscription && profile.subscription.plan && profile.subscription.plan.name || '';
      }
      return '';
    } catch (e) {
      return '';
    }
  }

  /**
   * send enterprise form data
   * @param res formData
   */
  enterpriseFormQuery(res: any): Observable<any> {
    const url = `${this.requestPlanEndpoint}support/enterprise/`;
    return this.appHttp.Post(url, res);
  }

  /**
   * to send request a quote form data
   * @param res formData
   */
  requestQuoteQuery(res: any) {
    const url = `${this.requestPlanEndpoint}pricing-quote/`;
    return this.appHttp.Post(url, res);
  }

  /**
   * to send the enterprise request with the price calculations
   * @param res formData
   */
  enterpriseEstimationQuery(res: any): Observable<any> {
    const url = `${this.requestPlanEndpoint}support/enterprise/custom-price/`;
    return this.appHttp.Post(url, res);
  }
}
