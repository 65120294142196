import {Injectable} from "@angular/core";
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {AppHttpClient} from "../../../app-http-client";
import {ActivatedRoute, Router} from "@angular/router";
import {catchError, forkJoin, Observable, throwError} from "rxjs";
import {tap} from "rxjs/operators";
import {
  LoginData,
  LoginResponse,
  MFALoginResponse,
  MFARecoveryCodeItem,
  MFASetupAppResponse,
  MFASetupEmailOTPResponse,
  MFASetupResponse,
  MFAStatus,
  PasswordResetConfirmData,
  PasswordResetConfirmResponse,
  PasswordResetData,
  PasswordResetResponse,
  PasswordValidation,
  RegisterData,
  RegisterResponse
} from "../../models/auth/auth.model";
import {ProfileService} from "./profile.service";
import {TokenService} from "./token.service";
import {QuotaService} from "./quota.service";
import {UserProfileModel} from "../../models/auth/profile.model";
import {LanguageService} from "../language/language.service";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private endpoint = 'auth/';
  private apiURL = environment.apiUrl;

  /**
   * key to store user data
   */
  private localStorageUserKey = 'userProfile';
  mfaType: 'email' | 'authenticator_app';

  /**
   * AuthService constructor
   *
   * @param http AppHttpClient
   * @param appHttp AppHttpClient for endpoints which does not need multi-part/form-data
   * @param tokenService TokenService
   * @param profileService profile service
   * @param  router router
   * @param activatedRoute activatedRoute
   * @param quotaService
   * @param language
   */
  constructor(
    private http: HttpClient,
    private appHttp: AppHttpClient,
    private tokenService: TokenService,
    private profileService: ProfileService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private quotaService: QuotaService,
    private language: LanguageService
  ) {
  }

  /**
   * Get refresh token from the TokenService
   */
  public get getRefreshToken(): string {
    return this.tokenService.refreshToken();
  }

  /**
   * Get the TokenType from the `TokenService`
   */
  public get tokenType(): string | boolean {
    return this.tokenService.tokenType();
  }

  /**
   * Get the access token from the `TokenService`
   */
  public get accessToken(): string {
    const $token = this.tokenService.accessToken();
    return $token ?? '';
  }

  /**
   * Login user to get `access_token`
   * @param data LoginData
   */
  public login(data: LoginData): Observable<LoginResponse> {
    const url = `${this.apiURL}${this.endpoint}token/`;

    const $data: {
      [key: string]: string
    } = (Object as any).assign({
      grant_type: 'password',
      client_id: environment.clientKey,
      client_secret: environment.clientSecret
    }, data);

    const formData = new FormData();
    for (const d in $data) {
      if ($data.hasOwnProperty(d)) {
        formData.append(d, $data[d]);
      }
    }

    return this.http.post<LoginResponse>(url, formData);
  }

  /**
   * Google login user
   * @param token Token received
   */
  public googleLogin(token: string): Observable<LoginResponse> {
    const url = `${this.apiURL}${this.endpoint}login/google/`;

    const data: {
      [key: string]: string
    } = {
      access_token: token,
      client_id: environment.clientKey,
      client_secret: environment.clientSecret
    };

    const formData = new FormData();
    for (const d in data) {
      if (data.hasOwnProperty(d)) {
        formData.append(d, data[d]);
      }
    }

    return this.http.post<LoginResponse>(url, formData);
  }

  /**
   * Get a new `access_token` from the `refresh_token`
   */
  public refreshToken(): Observable<LoginResponse> {
    console.trace('refresh token...');
    const url = `${this.apiURL}${this.endpoint}token/`;

    const data: {
      [key: string]: string
    } = {
      grant_type: 'refresh_token',
      client_id: environment.clientKey,
      client_secret: environment.clientSecret,
      refresh_token: this.getRefreshToken
    };

    const formData = new FormData();
    for (const d in data) {
      if (data.hasOwnProperty(d)) {
        formData.append(d, data[d]);
      }
    }

    // return this.http.post<LoginResponse>(url, formData).pipe(
    //   tap((response) => this.tokenService.saveCredentials(response))
    // );
    console.log('Requesting url: ', url, formData);
    return this.http.post<LoginResponse>(url, formData)
      .pipe(
        tap((response) => {
          console.log('refreshed...: ', response);
          return this.tokenService.saveCredentials(response)
        }),
        catchError(err => {
          console.log('refresh token error: ', err);
          return throwError(err);
        })
      )
  }

  /**
   * Register a new user account
   * @param data RegisterData
   */
  public register(data: RegisterData): Observable<RegisterResponse> {
    const url = `${this.endpoint}register/`;
    return this.appHttp.Post(url, data);
  }

  /**
   * Verify OTP while registering account
   * @param data
   */
  public verifyOtp(data: {[key: string]: string}): Observable<RegisterResponse> {
    const url = `${this.endpoint}register/verify-otp/`;

    return this.appHttp.Post(url, data);
  }

  /**
   * Resend registration OTP
   * @param data
   */
  public resendOtp(data: { email: any }) {
    const url = `${this.endpoint}register/resend-otp/`;

    return this.appHttp.Post(url, data);
  }

  /**
   * Send a forgot password request
   * @param data PasswordResetData
   */
  public passwordReset(data: PasswordResetData): Observable<PasswordResetResponse> {
    const url = `${this.endpoint}password/reset/`;

    return this.appHttp.Post(url, data);
  }

  /**
   * Reset password
   * @param data PassResetConfirmData
   */
  public passwordResetConfirm(data: PasswordResetConfirmData): Observable<PasswordResetConfirmResponse> {
    const url = `${this.endpoint}password/reset/confirm/`;

    return this.appHttp.Post(url, data);
  }

  /**
   * Logout user
   */
  public logout() {
    console.trace('logout called');
    return new Promise((resolve) => {
      console.log('revoking access');
      // this.socialAuth.signOut(true);
      this.revokeAccessToken().subscribe({
        next: () => {
          console.log('done...');
          resolve(this.tokenService.clearCredentials());
        },
        error: (error) => {
          console.error('logout error: ', error);
          resolve(this.tokenService.clearCredentials());
        }
      });
    });
  }

  /**
   * Return shortAnswer if user is valid or not
   * @return boolean True if user is valid, False otherwise.
   */
  public userValid(): boolean {
    return this.tokenService.userValid();
  }

  /**
   * Check if user is authenticated.
   * The check is performed against the expired access token.
   * If the access token is expired, return False.
   *
   * @return boolean True if user is authenticated, False otherwise.
   */
  public isAuthenticated(): boolean {
    return !this.tokenService.isTokenExpired();
  }

  /**
   * Validates the password
   * @return ..
   */
  public validatePassword(data: any): Observable<PasswordValidation> {
    const url = `${this.endpoint}password/validate/`;
    return this.appHttp.Post(url, data);
  }

  /**
   * Save user profile to the local storage
   */
  public saveUserProfile() {
    return new Promise((resolve, reject) => {
      this.profileService.getUser().subscribe({
        next: res => {
          this.profileService.updateUserProfile(res);
          resolve(res);
        },
        error: err => {
          reject(err);
          throw err;
        }
      });
    });
  }

  /**
   * Perform specific actions when user has logged in
   */
  public afterLogin(res: LoginResponse) {
    // Save token
    const saveToken = this.tokenService.saveCredentials(res);
    // Get user profile
    const saveProfile = this.saveUserProfile();

    // forkJoin
    return forkJoin([saveToken, saveProfile]);
  }

  /**
   * Verifies user's email
   * @param data is the key which is to be verified
   */
  public verifyEmail(data: object): Observable<any> {
    const url = `${this.endpoint}register/verify-email/`;
    return this.appHttp.Post(url, data);
  }

  // /**
  //  * Return the URL to navigate user to, after login process
  //  * The path will be decided based on the permission and user level
  //  */
  // public redirectToAfterLogin() {
  //   const user = this.getUserProfile();
  //   this.quotaService.storeQuotaAndPermissionsData(user);
  //
  //   return new Promise((resolve) => {
  //     if (this.quotaService.canCreateQR()) {
  //       return resolve('/qr/list');
  //     } else if (this.quotaService.canViewQR()) {
  //       return resolve('/qr/list');
  //     } else if (this.quotaService.canAccessAnalytics()) {
  //       return resolve('/analytics');
  //     } else if (this.quotaService.canAccessLeads()) {
  //       return resolve('/analytics');
  //     } else if (this.quotaService.canAccessSettings()) {
  //       return resolve('/settings');
  //     } else {
  //       return resolve('/');
  //     }
  //   });
  // }

  /**
   * Revokes access token
   */
  public revokeAccessToken() {
    const url = `${this.apiURL}${this.endpoint}token/revoke/`;

    const data: {
      [key: string]: string
    } = {
      token: this.accessToken,
      client_id: environment.clientKey,
      client_secret: environment.clientSecret
    };

    const formData = new FormData();
    for (const d in data) {
      if (data.hasOwnProperty(d)) {
        formData.append(d, data[d]);
      }
    }

    return this.http.post<any>(url, formData);
  }

  /**
   * Returns QCG Session ID or blank
   */
  public get mfaSessionId(): string {
    const sessionId = localStorage.getItem('mfa-session-id');
    return sessionId ? sessionId : '';
  }

  /**
   * Setup MFA
   * @param data
   */
  mfaSetup(data: {
    password: string;
    type: 'email' | 'authenticator_app'
  }): Observable<MFASetupEmailOTPResponse | MFASetupAppResponse> {
    const url = `${this.endpoint}mfa/setup/`;

    return this.appHttp.Post(url, data);
  }

  /**
   * MFA Setup Verification
   * @param data
   */
  mfaSetupVerify(data: { otp: string; type: 'email' | 'authenticator_app' }): Observable<MFASetupResponse> {
    const url = `${this.endpoint}mfa/setup/verify/`;

    return this.appHttp.Post(url, data);
  }

  /**
   * Verify MFA
   * @param data
   */
  mfaVerify(data: { [key: string]: any }): Observable<MFALoginResponse> {
    const url = `${this.apiURL}${this.endpoint}mfa/authenticate/`;

    const formData = new FormData();
    for (const d in data) {
      if (data.hasOwnProperty(d)) {
        formData.append(d, data[d]);
      }
    }

    return this.http.post<MFALoginResponse>(url, formData);
  }

  /**
   * MFA Resend OTP (email)
   */
  public mfaResendOtp(): Observable<any> {
    const url = `${this.endpoint}mfa/otp/resend/`;

    return this.appHttp.Get(url);
  }

  /**
   * Get status of all MFA setup
   */
  public mfaStatus(): Observable<MFAStatus> {
    const url = `${this.endpoint}mfa/check/`;

    return this.appHttp.Get(url);
  }

  /**
   * Get MFA Recovery codes
   * @param data
   */
  public mfaRecoveryCodes(data: { password: string }): Observable<Array<MFARecoveryCodeItem>> {
    const url = `${this.endpoint}mfa/recovery-codes/`;

    return this.appHttp.Post(url, data);
  }

  /**
   * Regenerate recovery codes
   * @param data
   */
  public mfaRecoveryCodeRegenerate(data: { password: string }): Observable<Array<MFARecoveryCodeItem>> {
    const url = `${this.endpoint}mfa/recovery-codes/regenerate/`;

    return this.appHttp.Post(url, data);
  }

  /**
   * Delete MFA Setup
   * @param data
   */
  public mfaDelete(data: { type: 'email' | 'authenticator_app', password: string }): Observable<any> {
    const url = `${this.endpoint}mfa/delete/`;

    return this.appHttp.Post(url, data);
  }

  /**
   * When User gets access token, these steps runs after it.
   *
   * 1. Redirect user to specific path based on the following checks
   *    a. Redirect to previous url if exits in `continue` query parameter
   *    b. Redirect shared user to the path based on the permission to the user
   *    c. By default, redirect to `qr/list`
   * 2. Change language to the user's preferred language before redirecting
   */
  async afterLoginSteps() {
    // Default url to redirect
    let url = '/';

    // Check if url has `?continue=...` parameter.
    // If exists, user will be redirected back to the `continue` url.
    this.activatedRoute.queryParams.subscribe({
      next: params => {
        if (params?.['continue'])
          url = params['continue'];
      }
    });

    // Update redirect url, in case of shared user (based on permission)
    if (this.getUserProfile()?.user_type === 'shared') {
      url = await this.getSharedUserRedirectUrl();
    }

    console.log('url...: ', url);

    this.router.navigate([url]);

    // Redirect user to the `url` after changing language to user's preferred language
    // this.language.changeLanguage(this.getUserProfile()?.preference?.language || 'en', url);
  }

  /**
   * Return the URL to navigate user to, after login process
   * The path will be decided based on the permission and user level
   */
  public getSharedUserRedirectUrl(): Promise<string> {
    const user = this.getUserProfile();
    this.quotaService.storeQuotaAndPermissionsData(user as UserProfileModel);

    return new Promise((resolve) => {
      if (this.quotaService.canCreateQR()) {
        return resolve('/qr/list');
      } else if (this.quotaService.canViewQR()) {
        return resolve('/qr/list');
      } else if (this.quotaService.canAccessAnalytics()) {
        return resolve('/analytics');
      } else if (this.quotaService.canAccessLeads()) {
        return resolve('/leads/list');
      } else if (this.quotaService.canAccessBulkOperation()) {
        return resolve('/bulk-operations');
      } else if (this.quotaService.canAccessSettings()) {
        return resolve('/settings');
      } else if (this.quotaService.canAccessSubscription()) {
        return resolve('/subscription');
      } else {
        return resolve('/');
      }
    });
  }

  /**
   * Gets saved User Profile from local storage
   */
  public getUserProfile() {
    try {
      return JSON.parse(<string>localStorage.getItem(this.localStorageUserKey)) as UserProfileModel;
    } catch (e) {
      return null;
    }
  }
}
